import React, { useState, useEffect } from 'react';
import logo from '../logo.svg';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText'
import ReportIcon from '@mui/icons-material/Report';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Header from '../components/header/header';


export default function Lavado(props) {

  const [dense, setDense] = React.useState(false);
  const [exp1, setExp1] = React.useState(true);


  return (
    <div className="App">
      
      <Header />
      <Container>
          <Accordion expanded={exp1} onChange={() => setExp1(true)} style={{marginBottom:"2em"}}>
            <AccordionSummary  className="titleAccordion"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ReportIcon style={{marginRight:"1em"}}/> <Typography>INSTRUCIONES DE LAVADO</Typography>
            </AccordionSummary>
            <AccordionDetails  className="bodyAccordion">
            <ol>
              <li style={{textAlign: "left"}}>CARGUE LA LAVADORA Y CIERRE LA PUERTA <small>(recuerda revisar bolsillos y cerrar cremalleras )</small></li>
              <li style={{textAlign: "left"}}>DIRÍJASE A LA CENTRAL Y SIGA LAS INSTRUCCIONES <small>( escoja el programa que mejor se adapte, recuerde que los jabones están incluidos y puede usted añadir uno de nuestros suavizantes sin coste adicional GRATIS, en caso contrario el programa no añadira suavizante por si tienes problemas de piel, ropa deportiva, personas encamadas, etc)</small></li>
              <li style={{textAlign: "left"}}>VUELVA A LA LAVADORA Y CONFIRME INICIO <small>( la puerta se mantendrá bloqueada hasta el fin de ciclo)</small></li>
            </ol>
            <p><b>Información sobre los diferentes extras</b></p>
            <ul>
              <li style={{textAlign: "left"}}>Prelavado Extra (desengrasante profesional): indicado para ropa muy sucia, con manchas de grasa o ropa laboral.</li>
              <li style={{textAlign: "left"}}>Aclarado Extra (no quedara ni rastro de jabón): Especialmente util en tejidos que exijan una ausencia total de detergentes, ropa de bebes, gente encamada,ropa deportiva,etc..</li>
              <li style={{textAlign: "left"}}>Centrifugado Super (ahorra en secado): Reduce la humedad residual al final del ciclo,mejorando la eficacia del secado; resulta muy útil en tejidos gruesos, mantas, nórdicos, etc</li>
              <li style={{textAlign: "left"}}>Suavizantes : Podrá escoger entre nuestros dos suavizares sin ningún tipo de coste adicional, Acura Soft con aroma citronela o Muschio Bianco con aroma almizcle blanco</li>
            </ul>
            </AccordionDetails>
          </Accordion>
          
      </Container>
    </div>
  );
}