import React, { useState, useEffect } from 'react';
import logo from '../../logo.svg';

import { ArrowBack } from '@mui/icons-material';
import {BrowserRouter as Router, Route, Redirect,Switch, useHistory } from 'react-router-dom';



export default function Header(props) {

    const history = useHistory();

  return (
      <header className="App-header" style={{flexDirection: "row", color: "black", marginBottom: "1em", borderBottom: "1px solid #ccc"}}>
       <ArrowBack style={{fontSize: "30px", marginRight: "2em"}} onClick={history.goBack}/> <img src={logo} alt="logo" className="logo" style={{maxWidth: "50%"}}/>
      </header>
  );
}