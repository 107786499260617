import React, { useState, useEffect } from 'react';
import logo from '../logo.svg';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText'
import ReportIcon from '@mui/icons-material/Report';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Header from '../components/header/header';


export default function Importantes(props) {

  const [dense, setDense] = React.useState(false);
  const [exp1, setExp1] = React.useState(true);


  return (
    <div className="App">
      
      <Header />
      <Container>
          <Accordion expanded={exp1} onChange={() => setExp1(true)} style={{marginBottom:"2em"}}>
            <AccordionSummary  className="titleAccordion"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ReportIcon style={{marginRight:"1em"}}/> <Typography>IMPORTANTE</Typography>
            </AccordionSummary>
            <AccordionDetails  className="bodyAccordion">
            <List dense={dense}>
                <ListItem> <ListItemIcon> <PriorityHighIcon />  </ListItemIcon>  <ListItemText primary="Esta prohibido lavar alfombras, zapatos y ropa utilizada por mascotas. (Disponemos de una lavadora y una secadora especificas, con productos especiales adecuados para la ropa de su mascota)" /> </ListItem>
                <ListItem> <ListItemIcon> <PriorityHighIcon />  </ListItemIcon>  <ListItemText primary="No introduzca artículos de caucho, plástico o rígidos en las maquinas" /> </ListItem>
                <ListItem> <ListItemIcon> <PriorityHighIcon />  </ListItemIcon>  <ListItemText primary="No añadir productos en los lavados. Todas las lavadoras incluyen detergente y oxigeno activo, los programas NO llevan suavizantes, en los extras podrá seleccionar sin ningún tipo de coste, (GRATIS) entre nuestros dos suavizantes el que mas le guste (no mezclar)" /> </ListItem>
                <ListItem> <ListItemIcon> <PriorityHighIcon />  </ListItemIcon>  <ListItemText primary=" La empresa no se responsabiliza de las pertenencias que el cliente pueda dejar olvidadas o al alcance de otros usuarios." /> </ListItem>
                <ListItem> <ListItemIcon> <PriorityHighIcon />  </ListItemIcon>  <ListItemText primary="La empresa no esta obligada a tener un asistente personal en la lavandería, por lo que , no se hace responsable de los posibles deterioros que puedan sufrir las prendas en el lavado o secado, debido a un mal uso de la maquinaria o por incumplimiento de las normas y recomendaciones de uso de la lavandería por parte del usuario." /> </ListItem>
                <ListItem> <ListItemIcon> <PriorityHighIcon />  </ListItemIcon>  <ListItemText primary="En caso de incidencia que no se pueda resolver por teléfono,la asistencia al cliente se hará siempre en el menor tiempo posible, pudiendo no ser de manera inmediata." /> </ListItem>
                <ListItem> <ListItemIcon> <PriorityHighIcon />  </ListItemIcon>  <ListItemText primary="Por su seguridad, esta totalmente prohibida la manipulación de las maquinas por niños" /> </ListItem>
              
            </List>
            </AccordionDetails>
          </Accordion>
          
      </Container>
    </div>
  );
}