import React, { useState, useEffect } from 'react';
import logo from '../logo.svg';
import ic_lavado from '../ic_lavado.png';
import ic_secado from '../ic_secado.png';
import ic_normas from '../ic_normas.png';
import ic_importante from '../ic_importante.png';

import Container from '@mui/material/Container';
import { getToolbarUtilityClass, Grid } from '@mui/material';
import { useParams,useHistory } from "react-router-dom";


export default function Home(props) {

    const history = useHistory();

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" className="logo" style={{maxWidth: "50%"}}/>
      </header>
      <Container >
        
          <Grid container spacing={0} style={{padding: "20px", margin: "0px", width: "100%"}}>
                <Grid item sm={6} xs={6} style={{padding: "1px", justifyContent: "center", alignContent: "center"}}>
                    <div style={{cursor: "pointer", border:"1px solid #ccc", borderRadius: "5px", padding: "0.5em", justifyContent: "center", alignContent: "center", display: "flex", width: "calc(100% - 2px)", flexDirection: "column"}} onClick={() => { history.push("importante");} }>
                        <img src={ic_importante} style={{width: "100%", height:"auto"}}/>
                        <span style={{fontSize: "0.9em"}}>Importante</span>
                    </div>
                </Grid>
                <Grid item sm={6} xs={6} style={{padding: "1px", justifyContent: "center", alignContent: "center"}}>
                    <div style={{cursor: "pointer", border:"1px solid #ccc", borderRadius: "5px", padding: "0.5em", justifyContent: "center", alignContent: "center", display: "flex", width: "calc(100% - 2px)", flexDirection: "column"}} onClick={() => {history.push("recomendaciones");} }>
                        <img src={ic_normas} style={{width: "100%", height:"auto"}}/>
                        <span style={{fontSize: "0.9em"}}>Recomendaciones</span>
                    </div>
                </Grid>
                <Grid item sm={6} xs={6} style={{ padding: "1px", justifyContent: "center", alignContent: "center"}}>
                    <div style={{cursor: "pointer", border:"1px solid #ccc", borderRadius: "5px", padding: "0.5em", justifyContent: "center", alignContent: "center", display: "flex", width: "calc(100% - 2px)", flexDirection: "column"}} onClick={() => {history.push("lavado");}}>
                        <img src={ic_lavado} style={{width: "100%", height:"auto"}}/>
                        <span style={{fontSize: "0.9em"}}>Instrucciones Lavado</span>
                    </div>
                </Grid>
                <Grid item sm={6} xs={6} style={{padding: "1px", justifyContent: "center", alignContent: "center"}}>
                    <div style={{cursor: "pointer", border:"1px solid #ccc", borderRadius: "5px", padding: "0.5em", justifyContent: "center", alignContent: "center", display: "flex", width: "calc(100% - 2px)", flexDirection: "column"}} onClick={() => {history.push("secado");}}>
                        <img src={ic_secado} style={{width: "100%", height:"auto"}}/>
                        <span style={{fontSize: "0.9em"}}>Instrucciones Secado</span>
                    </div>
                </Grid>

          </Grid>  
          
      </Container>
    </div>
  );
}

