import React, { useState, useEffect } from 'react';
import logo from '../logo.svg';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText'
import ReportIcon from '@mui/icons-material/Report';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Header from '../components/header/header';


export default function Secado(props) {

  const [dense, setDense] = React.useState(false);
  const [exp1, setExp1] = React.useState(true);


  return (
    <div className="App">
      
      <Header />
      <Container>
          <Accordion expanded={exp1} onChange={() => setExp1(true)} style={{marginBottom:"2em"}}>
            <AccordionSummary  className="titleAccordion"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ReportIcon style={{marginRight:"1em"}}/> <Typography>INSTRUCIONES DE SECADO</Typography>
            </AccordionSummary>
            <AccordionDetails  className="bodyAccordion">
            <ol>
              <li style={{textAlign: "left"}}>CARGUE LA SECADORA Y CIERRE LA PUERTA <small>(recuerde revisar etiquetas y verificar que las prendas introducidas admiten secado en secadora)</small></li>
              <li style={{textAlign: "left"}}>DIRIJASE A LA CENTRAL DE PAGO Y SIGA LAS INSTRUCCIONES 
                <ul>
                  <li>puede seleccionar la temperatura y variarla durante el proceso directamente en la maquina</li>
                  <li>puede abrir la puerta en cualquier momento, al cerrarla continuara el proceso.</li>
                  <li>si lo desea puede añadir tiempo de secado, realizando pago adicional siempre 3 minutos antes de finalizar el programa</li>
                </ul>
              </li>
              <li style={{textAlign: "left"}}>VDIRIJASE NUEVAMENTE A LA SECADORA Y CONFIRME EL INICIO DEL CICLO</li>
            </ol>
            </AccordionDetails>
          </Accordion>
          
      </Container>
    </div>
  );
}