import logo from './logo.svg';
import * as React from 'react';
import './App.css';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText'
import FolderIcon from '@mui/icons-material/Folder';
import ReportIcon from '@mui/icons-material/Report';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CampaignIcon from '@mui/icons-material/Campaign';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import {BrowserRouter as Router, Route, Redirect,Switch } from 'react-router-dom';
import Home from './page/Home';
import Recomendaciones from './page/Recomendaciones';
import Importantes from './page/Importantes';
import Lavado from './page/Lavado';
import Secado from './page/Secado';

function App() {

  
  return (
      <>
      <Router>
        <Switch>
          <Route path="/importante" component={Importantes} />
          <Route path="/recomendaciones" component={Recomendaciones} />
          <Route path="/lavado" component={Lavado} />
          <Route path="/secado" component={Secado} />
          <Route path="/" component={Home} />
          
        </Switch>
      </Router>
      </>
  );
}

export default App;
