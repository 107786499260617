import React, { useState, useEffect } from 'react';
import logo from '../logo.svg';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText'
import CampaignIcon from '@mui/icons-material/Campaign';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import Header from '../components/header/header';


export default function Recomendaciones(props) {

  const [dense, setDense] = React.useState(false);
  const [exp2, setExp2] = React.useState(true);


  return (
    <div className="App">
      <Header />
      <Container>
          
      <Accordion  expanded={exp2} onChange={() => setExp2(true)} style={{marginBottom:"2em"}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              className="titleAccordion"
            >
              <CampaignIcon style={{marginRight:"1em"}}/> <Typography>RECOMENDACIONES</Typography>
            </AccordionSummary>
            <AccordionDetails  className="bodyAccordion">
            <List dense={dense}>
                <ListItem> <ListItemIcon> <ScatterPlotIcon />  </ListItemIcon>  <ListItemText primary="No exceder la capacidad máxima de las maquinas, en el lavado el exceso de ropa impedirá que se laven bien las prendas y podrá provocar que la maquina por seguridad no realice el centrifugado, y en el secado impedirá un correcto y homogéneo secado de sus prendas." /> </ListItem>
                <ListItem> <ListItemIcon> <ScatterPlotIcon />  </ListItemIcon>  <ListItemText primary="Escoger el programa adecuado siguiendo las especificaciones marcadas en las etiquetas de sus prendas" /> </ListItem>
                <ListItem> <ListItemIcon> <ScatterPlotIcon />  </ListItemIcon>  <ListItemText primary="Antes de cargar las lavadoras o secadoras, comprobar que no hay cuerpos extraños o cualquier desperfecto en el interior de las maquinas. Si se observa alguna anomalía, no introducir las prendas y contactar atraves del numero de atención al cliente." /> </ListItem>
                <ListItem> <ListItemIcon> <ScatterPlotIcon />  </ListItemIcon>  <ListItemText primary="Revisar los bolsillos y cerrar cremalleras antes de utilizar las maquinas" /> </ListItem>
            </List>
            </AccordionDetails>
          </Accordion>
          
      </Container>
    </div>
  );
}